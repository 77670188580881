import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import { getRoomDetails, getRoomNumbers } from "./apis/api";

export default function RegistrationFormComponent() {
  const [isChecked, setIsChecked] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [roomDetails, setRoomDetails] = useState([]);
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState();
  const [date1, setDate1] = useState(0);
  const initialPayload = {
    hotel_code: "",
    from_date: "",
    to_date: "",
    total_sale_amount: "",
    total_without_tax: "",
    tax_amount: "",
    total_services_amount: "",
    service_charge: "",
    gross_amount: "",
    discount_type: "",
    discount_amount: "",
    minimum_advance: "",
    payment_mode: "",
    no_of_nights: "",
    paid_amount: "",
    balance_amount: "",
    first_name: "",
    last_name: "",
    email: "",
    market_segment: "",
    address: "",
    phone_number: "",
    mobile_number: "",
    country: "NL",
    booking_status: "",
    check_in_time: "",
    room_type_id: "",
    rate_plan_amount: "",
    booking_type: "",
    no_of_rooms: "",
    covered_services: "",
    room_assinged_adult_info: [
      {
        no_of_adults: "1",
        no_of_child: "0",
        no_of_rooms: "1",
        room_id: "",
      },
    ],
    room_booking: [
      {
        room_id: "",
        no_of_pax: "",
        no_of_adults: "",
        no_of_children: "",
        no_of_rooms: "",
        total_sale_amount: "",
        discount_amount: "",
        coupon_amount: "",
        total_without_tax: "",
        tax_amount: "",
        gross_amount: "",
      },
    ],
    booking_service: [],
    other_members: [],
    gst_details: [
      {
        corporate_name: "",
        gst_number: "",

        main_office_address: "",
      },
    ],
  };
  const [payload, setPayload] = useState(initialPayload);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleRoomTypeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = roomDetails.find(
      (room) => room.value == selectedValue
    );

    if (selectedObject) {
      setSelectedRoomType(selectedObject);
    }
  };

  useEffect(() => {
    if (roomDetails.length > 0) {
      setSelectedRoomType(roomDetails[0]);
    }
  }, [roomDetails]);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const roomDetailsData = await getRoomDetails();
        const newRoomDetails = roomDetailsData.data.map((room) => ({
          value: room.id,
          label: room.room_type_name,
          price: room.price,
        }));
        setRoomDetails(newRoomDetails);

        if (newRoomDetails.length > 0) {
          setSelectedRoomType(newRoomDetails[0].value);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      }
    };

    fetchRoomDetails();
  }, []);

  useEffect(() => {
    const fetchRoomNumbers = async () => {
      try {
        const data1 = await getRoomNumbers(selectedRoomType.value);

        if (!data1 || !data1.data || data1.data.length === 0) {
          setRoomNumbers([]);
        }

        const newRoomNumbers = data1.data.map((room) => ({
          value: room.room_type_id,
          label: room.room_name,
          room_id: room.id,
        }));
        setRoomNumbers(newRoomNumbers);
      } catch (error) {
        console.error("Error fetching room numbers:", error);
      }
    };

    if (selectedRoomType) {
      fetchRoomNumbers();
    }
  }, [selectedRoomType]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().split("T")[0];

  const checkInDateObj = new Date(checkInDate);
  const nextDay = new Date(checkInDateObj);
  nextDay.setDate(nextDay.getDate() + 1);

  const minCheckOutDate = nextDay.toISOString().split("T")[0];

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    console.log(formData.get("Room_number"));

    formDataObj.room_assinged_adult_info = [
      {
        no_of_adults: formData.get("No_of_Adults"),
        no_of_child: formData.get("No_of_Children"),
        no_of_rooms: "1",
        room_id: formData.get("Room_number"),
      },
    ];
    formDataObj.room_booking = [
      {
        room_id: formData.get("Room_number"),
        no_of_pax: "",
        no_of_adults: formData.get("No_of_Adults"),
        no_of_children: formData.get("No_of_Children"),
        no_of_rooms: "",
        total_sale_amount: "",
        discount_amount: "",
        coupon_amount: "",
        total_without_tax: "",
        tax_amount: "",
        gross_amount: "",
      },
    ];

    if (isChecked) {
      formDataObj.gst_details = [
        {
          corporate_name: formData.get("Registration_Company_name"),
          gst_number: formData.get("Registration_no"),
          main_office_address: formData.get("Registration_Company_Address"),
        },
      ];
    } else {
      formDataObj.gst_details = [
        {
          corporate_name: "",
          gst_number: "",
          main_office_address: "",
        },
      ];
    }
    formDataObj.from_date = formData.get("Check_in_Date");
    formDataObj.to_date = formData.get("Check_Out_Date");
    formDataObj.no_of_nights =
      parseInt(formData.get("Check_Out_Date").split("-")[2]) - date1;

    setPayload({ ...initialPayload, ...formDataObj });
  };

  return (
    <>
      <Navbar username="" />
      <form
        className="mx-auto p-4 mt-20"
        style={{ maxHeight: "calc(100vh - 5rem)" }}
        onSubmit={handleSubmitForm}
      >
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="date"
              name="Check_in_Date"
              value={checkInDate}
              min={minDate}
              id="Check_in_Date"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              onChange={(e) => {
                setDate1(e.target.value.split("-")[2]);
                setCheckInDate(e.target.value);
              }}
            />
            <label
              htmlFor="Check_in_Date"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Check-in Date
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="date"
              name="Check_Out_Date"
              id="Check_Out_Date"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              min={checkInDate ? minCheckOutDate : ""}
              value={checkOutDate}
              required
              disabled={!checkInDate}
              onChange={(e) => {
                setCheckOutDate(e.target.value);
              }}
            />
            <label
              htmlFor="Check_Out_Date"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Check-Out Date
            </label>
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="Guest_First_Name"
              id="Guest_First_Name"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="Guest_First_Name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Guest First Name
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="Guest_Last_Name"
              id="Guest_Last_Name"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="Guest_Last_Name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Guest Last Name
            </label>
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <select
              name="No_of_Adults"
              id="No_of_Adults"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <label
              htmlFor="No_of_Adults"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Number of adults
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <select
              name="No_of_Children"
              id="No_of_Children"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <label
              htmlFor="No_of_Children"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Number of children
            </label>
          </div>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="email"
            name="Guest_email"
            id="Guest_email"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            htmlFor="Guest_email"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Guest Email
          </label>
        </div>

        <div className="relative z-0 w-full mb-5 group">
          <input
            type="number"
            name="Guest_Phone_number"
            id="Guest_Phone_number"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            htmlFor="Guest_Phone_number"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Guest Phone
          </label>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <select
              name="Room_Type"
              id="Room_Type"
              type="text"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              onChange={(e) => {
                handleRoomTypeChange(e);
              }}
            >
              {roomDetails.map((roomDetail, index) => (
                <option key={index} value={roomDetail.value}>
                  {roomDetail.label}
                </option>
              ))}
            </select>
            <label
              htmlFor="Room_Type"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Room Type
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <select
              name="Room_number"
              id="Room_number"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
            >
              {roomNumbers.length === 0 ? (
                <option value="">No rooms</option>
              ) : (
                roomNumbers.map((roomNumber, index) => (
                  <option key={index} value={roomNumber.room_id}>
                    {roomNumber.label}
                  </option>
                ))
              )}
            </select>
            <label
              htmlFor="Room_number"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Room Number
            </label>
          </div>
        </div>

        <div className="flex items-start mb-6">
          <div className="flex items-center h-5">
            <input
              id="remember"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              value=""
              className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
            />
          </div>
          <label
            htmlFor="remember"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-900"
          >
            Enter GSTIN details
          </label>
        </div>
        {isChecked && (
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="number"
                name="Registration_no"
                id="Registration_no"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="Registration_no"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Registration Number
              </label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="Registration_Company_name"
                id="Registration_Company_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="Registration_Company_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Registered Company Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="Registration_Company_Address"
                id="Registration_Company_Address"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="Registration_Company_Address"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Registered Company Address
              </label>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center">
          <input
            type="text"
            readOnly
            className="border-2 border-gray-300 rounded-lg p-2 w-1/2"
            placeholder={`Total Amount - ${selectedRoomType?.price} `}
          />
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}
