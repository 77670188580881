import React, { useState } from "react";

const Navbar = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className="flex justify-between items-center p-8 pb-4 bg-blue-500 text-white w-full "
      style={{
        backgroundColor: "rgb(23 26 71)",
        top: 0,
        zIndex: 1000,
        width: "100%",
        position: "fixed",
      }}
    >
      <div>{username}</div>
      <div className="relative">
        <button onClick={() => setIsOpen(!isOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
            />
          </svg>
        </button>
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
            <a
              href=""
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white"
            >
              Language
            </a>
            <a
              href=""
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white"
            >
              FAQ
            </a>
            {/* Add more links as needed */}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
