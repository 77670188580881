import React, { useContext, useEffect } from "react";
import Navbar from "./navbar";
import Lottie from "lottie-react";
import animationData from "./assets/ani4.json";
import front_photo from "./assets/front_photo.jpg";

function Helpdesk() {
  return (
    <div className="max-w-screen mx-auto">
      <Navbar username="" />
      <div
        style={{
          backgroundImage: `url(${front_photo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "92.5vh",
        }}
        className="mt-16 rounded p-4 bg-white grid place-items-center h-screen"
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
          <div
            className="w-full rounded-lg sm:max-w-md xl:p-0"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
              <div className="text-xl font-bold leading-tight tracking-tight text-1xl text-white">
                <Lottie animationData={animationData} speed={5} />
                <h1>Contact Frontdesk for further details.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Helpdesk;
