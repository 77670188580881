import React from "react";

const TermsPopup = ({ show, toggle, title, content }) => {
  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg max-w-md">
        <h2 className="text-lg font-bold mb-4">{title}</h2>
        <p className="text-sm">{content}</p>
        <div className="text-center mt-6">
          <button
            onClick={toggle}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsPopup;
