import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const userLogin = (data) => {
    sessionStorage.setItem("userData", JSON.stringify(data));
    setUserData(data);
  };

  const userLogout = () => {
    sessionStorage.removeItem("userData");
    setUserData(null);
  };

  return (
    <AuthContext.Provider value={{ userData, userLogin, userLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
