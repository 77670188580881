import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import front_photo from "./assets/front_photo.jpg";

export default function Dashboard() {
  const hotelCode = window.location.pathname.split("/")[1];
  const navigate = useNavigate();

  return (
    <div className="max-w-screen mx-auto overflow-hidden">
      <Navbar username="" />
      <div
        style={{
          backgroundImage: `url(${front_photo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "92.5vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="mt-16 rounded p-4 bg-white shadow-lg"
      >
        <div className="flex justify-center gap-8 md:w-full pb-20">
          <button
            className="px-5 py-2 bg-gray-500 text-white rounded-full cursor-pointer"
            onClick={() => {
              console.log("clicked");
              navigate(`/${hotelCode}/login`);
            }}
          >
            Check-In
          </button>
          <button
            className={`px-5 py-2 rounded-full cursor-pointer ${
              true ? "bg-gray-300 text-gray-500" : "bg-gray-500 text-white"
            }`}
            onClick={() => {
              console.log("clicked");
              navigate(`/${hotelCode}/register_form`);
            }}
            disabled={true}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}
