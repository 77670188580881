import React, { useEffect, useState, useContext } from "react";
import Navbar from "./navbar";
import front_photo from "./assets/front_photo.jpg";
import { getCheckinOTP, validateCheckinOTP } from "./apis/api";
import SimpleSnackbar from "./snackBar";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";

export default function Login() {
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [otp, setOtp] = useState("");
  const [payload, setPayload] = useState({ booking_id: "", mobile_no: "" });
  const [snackbar, setSnackbar] = useState({ visible: false, message: "" });
  const [loading, setLoading] = useState(false);
  const hotelCode = window.location.pathname.split("/")[1];
  const { userLogin } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleClearClick = () => {
    setOtp("");
  };

  const handleSubmit = () => {
    if (otp.length === 4) {
      setLoading(true);
      validateCheckinOTP(otp, payload.mobile_no, payload.booking_id)
        .then((res) => {
          setLoading(false);
          if (
            res.status_message === "success" ||
            res.message === "opt verified"
          ) {
            const newData = { booking_id: payload.booking_id };
            userLogin(newData);
            navigate(`/${hotelCode}/aadhaar_validation`);
          } else {
            console.log("Invalid OTP");
            setSnackbar({ visible: true, message: res.message });
          }
        })
        .catch((error) => {
          console.error("Error validating OTP:", error);
          setSnackbar({
            visible: true,
            message:
              "An error occurred while validating the OTP. Please try again.",
          });
        });
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const handleGetOTP = () => {
    if (payload.mobile_no && payload.booking_id) {
      setLoading(true);
      getCheckinOTP(payload).then((res) => {
        setLoading(false);
        if (res.status_message === "success") {
          console.log(res.message);
          setIsOtpRequested(true);
          setTimeLeft(30);
        } else {
          console.log("Failed to get OTP");
          setSnackbar({ visible: true, message: res.message });
        }
      });
    } else {
      if (!payload.booking_id && !payload.mobile_no) {
        console.log("send booking id");
        setSnackbar({
          visible: true,
          message: "Please enter valid booking id and mobile no.",
        });
      } else if (!payload.mobile_no) {
        console.log("send mobile id");
        setSnackbar({ visible: true, message: "Please enter mobile number" });
      } else {
        console.log("send booking id");
        setSnackbar({ visible: true, message: "Please enter booking id" });
      }
    }
  };

  return (
    <div className="max-w-screen mx-auto">
      <Navbar username="" />
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 backdrop-blur-lg z-50">
          <div className="loader border-4 border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
        </div>
      )}
      <div
        style={{
          backgroundImage: `url(${front_photo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "92.5vh",
        }}
        className=" mt-16 rounded p-4 bg-white shadow-lg grid sm:grid-cols-1 md:grid md:grid-cols-1 md:grid-rows-3 md:gap-4 h-screen items-center"
      >
        <section
          className="rounded-3xl md:pt-96"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div
              className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <div className="text-xl font-bold leading-tight flex justify-center items-center tracking-tight  text-1xl  text-white">
                  Sign in to your account
                </div>
                <form className="space-y-4 md:space-y-6" action="#">
                  <div className="relative flex flex-col sm:flex-row items-center">
                    <div className="absolute start-0 top-0  items-center ps-3 pointer-events-none pt-3 lg:pt-5 md:pt-5 sm:pt-5">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 19 18"
                      >
                        <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                      </svg>
                    </div>
                    <input
                      type="number"
                      id="phone-input"
                      aria-describedby="helper-text-explanation"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-9/10 ps-10 p-2.5 transition duration-200 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                      placeholder="1234567890"
                      onChange={(e) => {
                        setPayload({ ...payload, mobile_no: e.target.value });
                      }}
                    />
                    <input
                      type="number"
                      id="booking_id_input"
                      aria-describedby="helper-text-explanation"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 lg:ps-5 md:ps-5 sm:ps-5 p-2.5 transition duration-200 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-9/10 m-1"
                      placeholder="BOOKING ID"
                      onChange={(e) => {
                        setPayload({ ...payload, booking_id: e.target.value });
                      }}
                    />
                    <div className="flex justify-start  items-center mt-2 sm:mt-0">
                      <button
                        type="button"
                        className={`rounded border-2 px-4 py-2 m-2 h-10 w-35 whitespace-nowrap ${
                          timeLeft ? "text-white" : "text-gray-400"
                        }`}
                        sytle={{
                          backgroundColor: "gray-500",
                        }}
                        onClick={() => {
                          handleGetOTP();
                        }}
                      >
                        {isOtpRequested
                          ? timeLeft > 0
                            ? `Resend in ${timeLeft} s`
                            : "Resend OTP"
                          : "Send OTP"}
                      </button>
                    </div>
                  </div>
                  {isOtpRequested && (
                    <div>
                      <div className="flex justify-center text-white">
                        Enter Verification Code
                      </div>
                      <div className="flex flex-col justify-center  items-center rounded">
                        {isOtpRequested && (
                          <div className="relative mt-3">
                            <input
                              type="tel"
                              pattern="[0-9]*"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              required
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 lg:ps-5 md:ps-5 sm:ps-5 p-2.5 transition duration-200 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-9/10 m-1"
                              placeholder="Enter OTP"
                            />
                          </div>
                        )}
                        <div className="flex justify-center mt-6">
                          <button
                            type="button"
                            className="border-2 text-white rounded px-4 py-2 m-2"
                            style={{
                              backgroundColor: "gray-500",
                            }}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="border-2 text-white rounded px-4 py-2 m-2"
                            style={{
                              backgroundColor: "gray-500",
                            }}
                            onClick={handleClearClick}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
        {snackbar.visible && (
          <SimpleSnackbar
            message={snackbar.message}
            setSnackbar={setSnackbar}
          />
        )}
      </div>
    </div>
  );
}
