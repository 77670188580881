import React from "react";

const WelcomePage = () => {
  return (
    <div>
      <h1>Welcome to Hotel Check-In Portal</h1>
      <p>Please enter a valid hotel code to proceed.</p>
      {/* Add your welcome page content here */}
    </div>
  );
};

export default WelcomePage;
