import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AadharValidation from "./components/aadharValidation";
import CheckInFormComponent from "./components/checkInFormComponent";
import Confirmation from "./components/confirmation";
import Dashboard from "./components/dashboard";
import Helpdesk from "./components/helpdesk";
import Login from "./components/login";
import RegistrationFormComponent from "./components/registrationFormComponent";
import WelcomePage from "./components/welcomePage";
import AuthContext from "./AuthContext";

const allowedHotelCodes = ["100087"];

const AppRouter = () => {
  const hotelCode = window.location.pathname.split("/")[1];
  const isHotelCodeAllowed = allowedHotelCodes.includes(hotelCode);
  const { userData } = useContext(AuthContext) || { booking_id: "" };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isHotelCodeAllowed ? (
              <Navigate to={`/${hotelCode}`} />
            ) : (
              <WelcomePage />
            )
          }
        />
        {isHotelCodeAllowed && (
          <Route path="/:hotelCode" element={<Dashboard />} />
        )}
        {isHotelCodeAllowed && (
          <>
            <Route path="/:hotelCode/login" element={<Login />} />
            <Route
              path="/:hotelCode/check_in_form"
              element={<CheckInFormComponent />}
            />
            <Route
              path="/:hotelCode/register_form"
              element={<RegistrationFormComponent />}
            />
            <Route
              path="/:hotelCode/aadhaar_validation"
              element={
                userData?.booking_id ? (
                  <AadharValidation />
                ) : (
                  <Navigate to={`/${hotelCode}`} />
                )
              }
            />
            <Route
              path="/:hotelCode/confirmation"
              element={
                userData?.booking_id ? (
                  <Confirmation />
                ) : (
                  <Navigate to={`/${hotelCode}`} />
                )
              }
            />
            <Route
              path="/:hotelCode/helpdesk"
              element={
                userData?.booking_id ? (
                  <Helpdesk />
                ) : (
                  <Navigate to={`/${hotelCode}`} />
                )
              }
            />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
